<template>
  <base-layout>
    <statistic-panel></statistic-panel>
    <div class="block full">
      <div class="block-title themed-background" style="margin-bottom:20px">
        <h2>Member</h2>
      </div>
      <div class="block-title" style="margin-bottom:20px">
        <ul class="nav nav-tabs" data-toggle="tabs">
          <li
            v-for="tab in tabs"
            :key="tab.name"
            :class="{ active: currentTab === tab }"
            @click="currentTab = tab"
          >
            <a href="javascript:void(0)">{{ tab.value }}</a>
          </li>
        </ul>
      </div>

      <keep-alive>
        <component :is="currentTabComponent"></component>
      </keep-alive>
    </div>
  </base-layout>
</template>

<script>
import MemberList from "@/components/member/ListItem";
import MemberAdd from "@/components/member/AddItem";
import MemberTree from "@/components/member/TreeItem";
import BaseLayout from "./layouts/Base";
import StatisticPanel from "@/components/dashboard/StatisticPanel";
export default {
  name: "Member",
  components: {
    BaseLayout,
    MemberList,
    StatisticPanel,
    MemberAdd,
    MemberTree
  },
  data: () => {
    const tabItems = [
      { name: "list", value: "Member list" },
      { name: "add", value: "Add new member" },
      { name: "tree", value: "Member tree" }
    ];
    return {
      members: [],
      tabs: tabItems,
      currentTab: tabItems[0]
    };
  },
  computed: {
    currentTabComponent() {
      return `member-${this.currentTab.name}`;
    }
  }
};
</script>

<template>
  <table v-if="treeData.name" class="table-reponsive">
    <tr>
      <td
        :colspan="Array.isArray(treeData.children) ? treeData.children.length * 2 : 1"
        :class="{
          parentLevel: Array.isArray(treeData.children) && treeData.children.length,
          extend: Array.isArray(treeData.children) && treeData.children.length && show_hide
        }"
      >
        <div :class="{ node: true, hasMate: treeData.mate }">
          <div
            class="person"
            style="cursor: pointer;"
            :class="Array.isArray(treeData.class) ? treeData.class : []"
          >
            <div class="body-img">
              <i class="fa fa-users"></i>
            </div>
            <div class="header-chart">
              <button class="inline-block btn text-white" @click="$emit('click-node', treeData)">
                <i class="fa fa-th-list"></i>
              </button>
              <div class="detail_id" @click="show_hide = !show_hide">{{ treeData.id }}</div>
            </div>
          </div>
          <!-- <template v-if="Array.isArray(treeData.mate) && treeData.mate.length">
              <div class="person" v-for="(mate, mateIndex) in treeData.mate" :key="treeData.name+mateIndex"
                :class="Array.isArray(mate.class) ? mate.class : []"
                @click="$emit('click-node', mate)"
              >
                <div class="avat">
                  <img :src="mate.image_url" />
                </div>
                <div class="name">{{mate.name}}</div>
              </div>
          </template>-->
        </div>
        <div
          v-if="Array.isArray(treeData.children) && treeData.children.length"
          class="extend_handle"
          @click="toggleExtend(treeData)"
        ></div>
      </td>
    </tr>
    <tr v-if="show_hide">
      <td
        v-for="(children, index) in treeData.children"
        :key="index"
        colspan="2"
        class="childLevel"
      >
        <TreeChart
          :json="children"
          @click-node="$emit('click-node', $event)"
          @click-child="$emit('click-child', $event)"
        />
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  name: "TreeChart",
  props: ["json"],
  data() {
    return {
      treeData: {},
      show_hide: false
    };
  },
  watch: {
    json: {
      handler: function(Props) {
        let extendKey = function(jsonData) {
          jsonData.extend = jsonData.extend === void 0 ? true : !!jsonData.extend;
          if (Array.isArray(jsonData.children)) {
            jsonData.children.forEach(c => {
              extendKey(c);
            });
          }
          return jsonData;
        };
        if (Props) {
          this.treeData = extendKey(Props);
        }
      },
      immediate: true
    }
  },
  methods: {
    toggleExtend: function(treeData) {
      treeData.extend = !treeData.extend;
      this.show_hide = !this.show_hide;
      this.$forceUpdate();
    }
  }
};
</script>

<style scoped>
.btn {
  position: absolute;
  right: 1px;
  top: 0px;
  height: 28px;
  padding-right: 5px;
  padding-left: 5px;
  width: 28px;
  color: #2189c7;
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  align-content: center;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.2) !important;
}
table {
  border-collapse: separate !important;
  border-spacing: 0 !important;
  width: 100%;
  overflow: auto;
  display: block;
}
td {
  position: relative;
  vertical-align: top;
  padding: 0 0 40px 0;
  text-align: center;
}
.extend_handle.hide::before,
.extend_handle.hide::after {
  display: none;
}
.extend_handle {
  position: absolute;
  left: 50%;
  bottom: 25px;
  width: 30px;
  height: 30px;
  padding: 10px;
  transform: translate3d(-15px, 0, 0);
  cursor: pointer;
}
.extend_handle:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 2px solid;
  border-color: #2189c7 #0ca7ec transparent transparent;
  -webkit-transform: rotateZ(135deg);
  transform: rotateZ(135deg);
  -webkit-transform-origin: 50% 50% 0;
  transform-origin: 50% 50% 0;
  transition: -webkit-transform ease 300ms;
  transition: transform ease 300ms;
  transition: transform ease 300ms, -webkit-transform ease 300ms;
}
.extend_handle:hover:before {
  border-color: #333 #333 transparent transparent;
}
.extend .extend_handle:before {
  transform: rotateZ(-45deg);
}
.extend::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 15px;
  height: 25px;
  border-left: 2px solid #ccc;
  transform: translate3d(-1px, 0, 0);
}
.childLevel::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 100%;
  height: 15px;
  border-left: 2px solid #ccc;
  transform: translate3d(-1px, 0, 0);
}
.childLevel::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: -15px;
  border-top: 2px solid #ccc;
}
.childLevel:first-child:before,
.childLevel:last-child:before {
  display: none;
}
.childLevel:first-child:after {
  left: 50%;
  height: 15px;
  border: 2px solid;
  border-color: #ccc transparent transparent #ccc;
  border-radius: 6px 0 0 0;
  transform: translate3d(1px, 0, 0);
}
.childLevel:last-child:after {
  right: 50%;
  height: 15px;
  border: 2px solid;
  border-color: #ccc #ccc transparent transparent;
  border-radius: 0 6px 0 0;
  transform: translate3d(-1px, 0, 0);
}
.childLevel:first-child.childLevel:last-child::after {
  left: auto;
  border-radius: 0;
  border-color: transparent #ccc transparent transparent;
  transform: translate3d(1px, 0, 0);
}
.node {
  position: relative;
  display: inline-block;
  margin: 0 1em;
  box-sizing: border-box;
  text-align: center;
}
.node .person {
  position: relative;
  display: inline-block;
  z-index: 2;
  width: 200px;
  overflow: hidden;
}
.node .person .avat {
  display: block;
  width: 4em;
  height: 4em;
  margin: auto;
  overflow: hidden;
  background: #fff;
  border: 1px solid #ccc;
  box-sizing: border-box;
}
.node .person .avat img {
  width: 100%;
  height: 100%;
}
.node .person .name {
  height: 2em;
  line-height: 2em;
  overflow: hidden;
  width: 100%;
}
.node.hasMate::after {
  content: "";
  position: absolute;
  left: 2em;
  right: 2em;
  top: 2em;
  border-top: 2px solid #ccc;
  z-index: 1;
}
/* 横板 */
.landscape {
  transform: translate(-100%, 0) rotate(-90deg);
  transform-origin: 100% 0;
}
.landscape .node {
  text-align: left;
  height: 8em;
  width: 8em;
}
.landscape .person {
  position: relative;
  transform: rotate(90deg);
  padding-left: 4.5em;
  height: 4em;
  top: 4em;
  left: -1em;
}
.landscape .person .avat {
  position: absolute;
  left: 0;
}
.landscape .person .name {
  height: 4em;
  line-height: 4em;
}
.landscape .hasMate {
  position: relative;
}
.landscape .hasMate .person {
  position: absolute;
}
.landscape .hasMate .person:first-child {
  left: auto;
  right: -4em;
}
.landscape .hasMate .person:last-child {
  left: -4em;
  margin-left: 0;
}
.body-img {
  border: 1px solid #0ca7ec;
  background: linear-gradient(to bottom, #2189c7, #0ca7ec) !important;
  padding: 5px;
  width: 100%;
  color: #fff;
  display: flex;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  align-self: center;
  height: 30px;
  align-items: center;
  align-content: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  justify-content: space-around;
}
.header-chart {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border: 1px solid #dedede;
  margin-left: auto;
  margin-right: auto;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.body-chart {
  width: 200px;
  border: 1px solid #0ca7ec;
  border-top: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 30px;
  display: flex;
  align-self: center;
  align-items: center;
  align-content: center;
  justify-content: center;
}
.body-img img {
  max-width: 100px;
  margin: auto;
}
.childLevel .body-img img {
  max-width: 80px;
}
.childLevel table .childLevel .body-img img {
  max-width: 60px;
}
.childLevel table .childLevel table .childLevel .body-img img {
  max-width: 60px;
}
.childLevel table .childLevel table .childLevel table .childLevel .body-img img {
  max-width: 60px;
}
</style>

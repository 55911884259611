<template>
  <div class="tab-content">
    <div class="block-section">
      <div class="row">
        <div class="form-group col-md-2">
          <input v-model="userID" type="text" class="form-control" placeholder="User id" />
        </div>
        <div class="form-group col-md-2">
          <input v-model="userEmail" type="text" class="form-control" placeholder="User email" />
        </div>
        <div class="form-group col-md-2">
          <datepicker
            v-model="registeredTime"
            value-type="format"
            input-class="form-control"
            placeholder="Registered Date"
            type="datetime"
          ></datepicker>
        </div>
        <div class="form-group col-md-2">
          <button type="button" class="btn btn-primary" @click="handleSearch">
            <i class="fa fa-search"></i> Search
          </button>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table
        class="table table-vcenter table-bordered table-hover table-striped table-condensed"
        v-if="hasItem"
      >
        <thead>
          <tr>
            <th>ID</th>
            <th>Email</th>
            <th>F</th>
            <th>Level Agency</th>
            <th>Volume Trade</th>
            <th>Registered Date</th>
            <th>Last Login</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="member in members" :key="member.User_ID">
            <td>{{ member.User_ID }}</td>
            <td>{{ member.UserEmail }}</td>
            <td>{{ member.F }}</td>
            <td>{{ member.LevelAgency }}</td>
            <td>{{ member.VolumeTrade }}</td>
            <td>{{ member.Created_Date }}</td>
            <td>{{ member.last_login || "NA" }}</td>
          </tr>
        </tbody>
        <tr v-if="totalPage > 1">
          <td colspan="5" class="text-right">
            <paginate
              v-model="page"
              :page-count="totalPage"
              :click-handler="handlePagination"
              :prev-text="'Prev'"
              :next-text="'Next'"
              :container-class="'pagination'"
              :page-class="'page-item'"
            ></paginate>
          </td>
        </tr>
      </table>

      <div v-else class="block-section">No member</div>
    </div>
  </div>
</template>

<script>
import memberAPI from "@/services/member";

export default {
  name: "MemberListItem",
  data: () => ({
    members: [],
    page: 1,
    totalPage: 1,
    userID: "",
    userEmail: "",
    registeredTime: ""
  }),
  created() {
    this.fetchMembers(1);
  },
  computed: {
    hasItem() {
      return this.members?.length > 0;
    }
  },
  methods: {
    async fetchMembers(page) {
      this.$startLoading();
      try {
        const response = await memberAPI.list(
          page,
          this.userID,
          this.userEmail,
          this.registeredTime
        );
        this.members = response.list;
        this.totalPage = response.total_page;
      } catch (err) {}
      this.$finishLoading();
    },
    handleSearch() {
      this.fetchMembers(this.page);
    },
    handlePagination(pageNum) {
      this.fetchMembers(pageNum);
    }
  }
};
</script>
<style scoped>
.mx-datepicker {
  width: calc(100% + 30px);
}
</style>

<template>
  <div class="tab-content">
    <div class="block-section">
      <div class="row">
        <div class="col-md-12">
          <TreeChart :json="MemberTree" @click-node="clickNode" @click-child="clickChild" />
        </div>
      </div>
    </div>
    <v-modal name="infoTree" :width="480" :height="'auto'" :adaptive="true" style="">
      <div
        class="flex items-center title px-5 py-5 sm:py-3 border-b border-gray-200 dark:border-dark-5 bg-theme-4"
      >
        Detail User: {{ detail.userID }}
      </div>
      <div class="p-5 grid grid-cols-12 gap-4 row-gap-3" style="padding:20px">
        <div class="col-span-12 xl:col-span-6">
          <label>User Email</label>
          <input
            v-model="detail.email"
            type="text"
            class="form-control border mt-2 flex-1"
            readonly
          />
        </div>
        <div class="col-span-12 xl:col-span-6">
          <label>User ID</label>
          <input
            v-model="detail.userID"
            type="text"
            class="form-control w-full border mt-2 flex-1"
            readonly
          />
        </div>
        <div class="col-span-12 xl:col-span-6">
          <label>Total Income</label>
          <input
            v-model="detail.totalEggs"
            type="text"
            class="form-control w-full border mt-2 flex-1"
            readonly
          />
        </div>
        <div class="col-span-12 xl:col-span-6">
          <label>Total play game</label>
          <input
            v-model="detail.totalPool"
            type="text"
            class="form-control w-full border mt-2 flex-1"
            readonly
          />
        </div>
        <div class="col-span-12 xl:col-span-6">
          <label>Level agency</label>
          <input
            v-model="detail.level_agency"
            type="text"
            class="form-control w-full border mt-2 flex-1"
            readonly
          />
        </div>
      </div>
      <div
        class="px-5 py-3 text-center border-t border-gray-200 dark:border-dark-5"
        style="padding-bottom:20px"
      >
        <button
          type="button"
          class="button w-20 btn btn-danger text-white"
          style="min-width:120px"
          @click="HideModal"
        >
          Cancel
        </button>
      </div>
    </v-modal>
  </div>
</template>

<script>
import TreeChart from "@/components/Vue-Tree.vue";
import { mapGetters } from "vuex";
export default {
  components: { TreeChart },
  name: "MemberTreeItem",
  computed: {
    ...mapGetters({
      TotalMember: "member/TotalMember",
      MemberTree: "member/Tree",
      Detail: "member/Detail"
    })
  },
  data() {
    return {
      detail: {
        email: "",
        userID: "",
        totalEggs: "loading",
        totalPool: "loading",
        level_agency: "none"
      },
      members: [],
      page: 1,
      totalPage: 1,
      userID: "",
      userEmail: "",
      registeredTime: ""
    };
  },
  mounted() {
    this.listMember = this.Member;
  },
  created() {
    this.$store.dispatch("member/req_getMemberTree", {
      page: this.currentPage
    });

    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case "member/addMemberSuccess":
          this.$store.dispatch("member/req_getMemberList", this.currentPage);
          this.$store.dispatch("member/req_getMemberTree", this.currentPage);
          break;
        case "member/getDetailSuccess":
          this.detail.totalEggs = this.Detail.data.data.total_egg;
          this.detail.totalPool = this.Detail.data.data.total_pool;
          this.detail.level_agency = this.Detail.data.data.level_agency.name;
          break;
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  methods: {
    clickNode: function(node) {
      this.$store.dispatch("member/req_getDetail", node.id);
      this.modalDetail(node.id, node.name);
    },
    HideModal() {
      this.$modal.hide("infoTree");
    },
    clickChild: function(node) {
      if (node.lastF) {
        this.listMember = this.Member_2;
      }
    },
    modalDetail(ID, Name, totalEggs, totalPool, level_agency) {
      this.detail.userID = ID;
      this.detail.email = Name;
      this.$modal.show("infoTree");
    },
    onDefaultTree() {
      this.listMember = this.Member;
    }
  }
};
</script>
<style scoped>
.mx-datepicker {
  width: calc(100% + 30px);
}
.title {
  text-align: center;
  background: #1e90ff;
  padding: 10px 0;
  font-size: 15px;
  font-weight: 700;
  color: white;
}
</style>
